/* eslint-disable */
import React from "react";
import CountryIcon from "../../assets/img/countries.png";
import MailIcon from "../../assets/img/mail.png";
import ContactIcon from "../../assets/img/call.png";
import OwnerIcon from "../../assets/img/owner.png";
import AddressIcon from "../../assets/img/pin.png";
import CloseIcon from "../../assets/img/close.png";
import DirectionIcon from "../../assets/img/direction.png";
import { getLandMarkSidebar } from '../../redux/actions/everShineActions';
import { useDispatch } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import GetAddressAPI from "../../API/GetAddressAPI";

const LandMarkDetails = (props) => {
    const { details, latitudeCurrent, longitudeCurrent } = props;
    const Dispatch = useDispatch();

    function generateDirectionsURL(origin, destination) {
        const baseURL = "https://www.google.com/maps/dir/?api=1";
        const originParam = `origin=${origin}`;
        const destinationParam = `destination=${destination}`;
    
        const fullURL = `${baseURL}&${originParam}&${destinationParam}`;
        
        return fullURL;
    };

    
    const gotoUrl = async () => {
        const responce = await GetAddressAPI(latitudeCurrent, longitudeCurrent);
        const startPoint = `${latitudeCurrent}, ${longitudeCurrent}`; // Latitude and Longitude of start point (New York City)
        const startPointAddress = `${responce?.results[0]?.formatted_address}`;
        // const endPoint = `${details?.landMarks?.latitude}, ${details?.landMarks?.longitude}`;  // Latitude and Longitude of end point (Los Angeles)
        const endPointAddress = `${details?.landMarks?.workshop_address}`;
        const directionsURL = generateDirectionsURL(startPoint, endPointAddress);
        window.open(directionsURL, '_blank');
    };

    return(<div className={details?.open ? "landmark-details-wrapper active" : "landmark-details-wrapper"}>
        <div className="landmarkImage">
            <img src={CloseIcon} className="close_icon"  onClick={() => Dispatch(getLandMarkSidebar({open: false, landMarks: {}}))} alt="clsoe" />
            <img src={details?.landMarks?.photos === null ? "https://cc.appristine.in/storage/media/crop/file_657412afb36c5.jpg" : details?.details?.photos} alt={details?.details?.workshop_name} className="landmark_background"  />
        </div>
        <div className="landmark_wrappps">
            <h1>
                <span>{details?.landMarks?.workshop_name}</span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top" style={{ position: "fixed" }}>Directions</Tooltip>}
                >
                    {/* <a href={`https://www.google.com/maps/dir/'${latitudeCurrent},${longitudeCurrent}'/'${details?.landMarks?.latitude},${details?.landMarks?.longitude}'`} target="__blank" className="direction_icon">
                        <img src={DirectionIcon} alt="directions" />
                    </a> */}
                    <a onClick={() => gotoUrl()} target="__blank" className="direction_icon">
                        <img src={DirectionIcon} alt="directions" />
                    </a>
                </OverlayTrigger>
            </h1>
            <ul>
                <li>
                    <img src={AddressIcon} alt="country"/> 
                    <div>{details?.landMarks?.workshop_address}</div>
                </li>
                <li>
                    <img src={OwnerIcon} alt="country"/> 
                    <div>{details?.landMarks?.owner_name}</div>
                </li>
                <li>
                    <img src={MailIcon} alt="country"/> 
                    <div>{details?.landMarks?.email}</div>
                </li>
                <li>
                    <img src={ContactIcon} alt="country"/>
                    <div>{details?.landMarks?.phone}</div>
                </li>
                <li>
                    <img src={CountryIcon} alt="country"/> 
                    <div>{details?.landMarks?.country}</div>
                </li>
            </ul>
            {/* <div className="serachLOcatinWrapper">
                <div className="searchWrpps">
                    <input type="text" className="form-control" placeholder="Your Location" />
                </div>
                <div className="searchWrppsBox">
                    {details?.landMarks?.workshop_address}
                </div>
            </div> */}
        </div>
    </div>)
};

export default LandMarkDetails;