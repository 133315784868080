export const ActionTypes = {
    GET_WORKSHOPS_LIST: "GET_WORKSHOPS_LIST",
    SELECT_LAND_MARK_SIDEBAR: "SELECT_LAND_MARK_SIDEBAR",
    GET_VEHICLE_TREATMENT_HISTORY: "GET_VEHICLE_TREATMENT_HISTORY"
};

export const getWorkShopsLists = (list) => {
    return {
       type: ActionTypes.GET_WORKSHOPS_LIST,
       payload: list,
    }
}

export const getLandMarkSidebar = (details) => {
    return {
        type: ActionTypes.SELECT_LAND_MARK_SIDEBAR,
        payload: details,
    }
}

export const getVehicleTreatMentHistory = (list) => {
    return {
        type: ActionTypes.GET_VEHICLE_TREATMENT_HISTORY,
        payload: list,
    }
}