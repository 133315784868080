import React from "react";
import AppStoreImage from "../../assets/img/app-store.png";
import PlayStoreStoreImage from "../../assets/img/play-store.png";

const FooterAppLinks = () => {
    return(<section className="footer_section">
        <a className="btn" href="https://play.google.com/store/apps/details?id=com.evershine.carservice&pli=1" target="__blank">
            <img src={PlayStoreStoreImage} alt="App Store" />
        </a>
        <a className="btn" href="https://apps.apple.com/us/app/evershine-coating/id6463494282" target="__blank">
            <img src={AppStoreImage} alt="App Store" />
        </a>
    </section>)
}

export default FooterAppLinks;