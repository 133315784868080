import { GET_WORKSHOP_API_URL } from "../components/Shared/constant";

const GetWorkShopsAPI = async (latitude, longitude, setLoaderStart) => {
    setLoaderStart(true);
    const responce = window.axios.get(`${GET_WORKSHOP_API_URL}?latitude=${latitude}&longitude=${longitude}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }}).then(function (result) {
        setLoaderStart(false);
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        setLoaderStart(false);
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default GetWorkShopsAPI;