const GetAddressAPI = async (latitude, longitude) => {
    const responce = fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${"AIzaSyAKUOwdqZdvKXs4NPrSvPpXknFstGlMppQ"}`)
        .then((response) => response.json())
        .then((data) => {
            return {
                ...data,
                status: 200,
                results: data?.results
              };
    }).catch((error) => {
        return {
            ...error,
            error
          }
    });
    return responce;
};
  
export default GetAddressAPI;