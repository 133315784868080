import React from "react";
import LoaderIcon from "../../assets/img/mapLoader.gif";
import LoaderVehicleIcon from "../../assets/img/loading.gif";
import { Image } from "react-bootstrap";

const CommonLoader = (props) => {
    const { type } = props;

    return type === "mapsearch" ? (<div className="CommonLoader">
        <h4>Loading....</h4>
        <Image src={LoaderIcon} alt="loader" />
        <span>This my take a minute</span>
    </div>) : (<div className="CommonLoader">
        <h4>Please Wait....</h4>
        <Image src={LoaderVehicleIcon} alt="loader" />
        <span>This my take a minute</span>
    </div>)
}

export default CommonLoader;