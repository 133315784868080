import React, { useState } from "react";
import GetVehicalTreatmentAPI from "../../API/GetVehicalTreatmentAPI";
import SearchVehicle from "../../assets/img/searchvehicle.png";
import { Link } from "react-router-dom";
import { HOME_URL } from "../Shared/constant";
import SearchIcon from "../../assets/img/searchIcon-white.png";
import SearchClearIcon from "../../assets/img/close_search.png";
import BackIcon from "../../assets/img/back.png";
import Logo from "../../assets/img/logo.png";
import EmptySpaceFieldValid from "../common/EmptySpaceFieldValid";
import CommonLoader from "../common/CommonLoader";

const CheckTreatment = () => {
    const [ vehicleTreatmentList, setVehicleTreatmentList ] = useState([]);
    const [ SearchCommon, setSearchCommon ] = useState("");
    const [ LoaderStart, setLoaderStart ] = useState(false);

    // search function
    const SearchButtonFunction = () => {
        if(SearchCommon !== "") {
            fetchData();
        };
    };

    // on Enter search
    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchButtonFunction();
        };
    };

    // Get data from api
    const fetchData = async () => {
        const responce = await GetVehicalTreatmentAPI(SearchCommon, setLoaderStart);
        if(responce?.status === 200) {
            setVehicleTreatmentList(responce?.data);
        } else {
            setVehicleTreatmentList(responce?.data);
        };
    };

    // clear search list
    const ClearSearch = () => {
        setVehicleTreatmentList([]);
        setSearchCommon("");
    };

    return(<React.Fragment>
        <header className="searchBoxWrapperMain">
            <Link to={HOME_URL} className="btn backbtn" >
                <img src={BackIcon} className="logoicon" alt="logo" />
            </Link>
            <div className="searchBoxWrapper">
                <img src={Logo} className="logoicon respohidelogo" alt="logo" />
                <div className="searchBox">
                    <input 
                        type="text" 
                        className="form-control" 
                        value={SearchCommon} 
                        onChange={(e) => setSearchCommon(e.target.value)}
                        onKeyDown={(e) => {
                            onEnterSearch(e);
                            EmptySpaceFieldValid(e);
                        }}
                        placeholder={"Search Vehicle Treatment ( Vehicle No )"}
                    />
                    {SearchCommon !== "" && (<button className="clearbtn" onClick={() => ClearSearch()}>
                        <img src={SearchClearIcon} alt="Clear Icon" />
                    </button>)}
                    <button className="btn btnsearchbX" onClick={SearchButtonFunction}>
                        <img src={SearchIcon} alt="Search Icon" />
                    </button>
                </div>
            </div>
        </header>
        <section className="checkTreatment_sectin">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="checkTreatS">
                            <div className="row">
                                {(vehicleTreatmentList?.length === 0 || LoaderStart) && (<div className="vehicle_empty_list">
                                    <img src={SearchVehicle} style={{ width: "auto" }} alt="SearchVehicle" />
                                    <h3>Search Vehicle Treatment</h3>
                                </div>)}
                                {vehicleTreatmentList?.map((elm, index) => {
                                    return(<div className="col-md-6" key={index}>
                                        <div className="vehiclDetailsWrapper">
                                            <h4>Work Order Number : #{elm?.job_id}</h4>
                                            <div className="vehicle_details_wraps">
                                                <div className="row">
                                                    <div className="col-md-6 column_details">
                                                        <label>JOB CREATED DATE & TIME:</label>
                                                        {elm?.job_created_date === null ? "-" : elm?.job_created_date}
                                                    </div>
                                                    <div className="col-md-6 column_details">
                                                        <label>COAT APPLIED DATE & TIME:</label>
                                                        {elm?.coat_applied_date === null ? "-" : elm?.coat_applied_date}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 column_details">
                                                        <label>VIN NUMBER:</label>
                                                        {elm?.VRN === null ? "-" : elm?.VRN}
                                                    </div>
                                                    <div className="col-md-6 column_details">
                                                        <label>VEHICLE MAKE:</label>
                                                        {elm?.vehicle_make === null ? "-" : elm?.vehicle_make}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 column_details">
                                                        <label>VEHICLE MODEL:</label>
                                                        {elm?.vehicle_model === null ? "-" : elm?.vehicle_model}
                                                    </div>
                                                    <div className="col-md-6 column_details">
                                                        <label>VEHICLE REGISTRATION YEAR:</label>
                                                        {elm?.vehicle_registration_year === null ? "-" : elm?.vehicle_registration_year}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 column_details">
                                                        <label>WORKSHOP NAME:</label>
                                                        {elm?.workshop_name === null ? "-" : elm?.workshop_name}
                                                    </div>
                                                    <div className="col-md-6 column_details">
                                                        <label>WORKSHOP ADDRESS:</label>
                                                        {elm?.workshop_address === null ? "-" : elm?.workshop_address }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 column_details">
                                                        <label>TECHNICIAN NAME:</label>
                                                        {elm?.technician_name === null ? "-" : elm?.technician_name }
                                                    </div>
                                                    <div className="col-md-6 column_details">
                                                        <label>TECHNICIAN CONTACT NO:</label>
                                                        {elm?.technician_phone === null ? "-" : elm?.technician_phone }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 column_details">
                                                        <label>PRODUCT VALIDITY TIME:</label>
                                                        {elm?.product_validity === null ? "-" : elm?.product_validity }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {LoaderStart && (<CommonLoader  type={"vehiclesearch"} />)}
    </React.Fragment>)
}

export default CheckTreatment;