import { VEHICALS_TREATMENT_HISTORY_API_URL } from "../components/Shared/constant";

const GetVehicalTreatmentAPI = async (SearchCommon, setLoaderStart) => {
    setLoaderStart(true);
    const responce = window.axios.get(`${VEHICALS_TREATMENT_HISTORY_API_URL}?vehicleNo=${SearchCommon}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }}).then(function (result) {
          setLoaderStart(false);
          return {
            ...result.data,
            status:200
          };
      }).catch(function (result) {
          setLoaderStart(false);
          return {
            ...result?.response?.data,
            status:result?.response?.status
          }
      });
      return responce;
};
  
export default GetVehicalTreatmentAPI;