import React from "react";
import NearByWorkIcon from "../../assets/img/NearByWorkIcon.png";
import TreatmentIcon from "../../assets/img/TreatmentIcon.png";
import { Link } from "react-router-dom";
import { NEAR_BY_WORKSHOP_URL, CHECK_YOUR_TREATMENT_URL } from "../Shared/constant";

const Home = () => {
    return(<section className="section_wraps">
        <Link to={NEAR_BY_WORKSHOP_URL} className="btn nearbtn">
            <img src={NearByWorkIcon} alt="NearByWorkIcon" />
        </Link>
        <Link to={CHECK_YOUR_TREATMENT_URL} className="btn checkTreatmentbtn">
            <img src={TreatmentIcon} alt="TreatmentIcon" />
        </Link>
    </section>)
}

export default Home;