import React from "react";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { HOME_URL } from "./constant";
import FooterAppLinks from "../common/FooterAppLinks";

const AuthLayout = ({children}) => {
    const Location = useLocation();

    return(<section className={Location?.pathname === HOME_URL ? "main_wrapper" : "body_wrapper"}>
        <Header />
        <div className="panelContentwrapper">
            {children}
        </div>
        <FooterAppLinks />
    </section>)
};

export default AuthLayout;