/* eslint-disable */
import { ActionTypes } from "../actions/everShineActions";

const initailData = {
    shopLists: [],
    landMarkSidebar: {
        open: false,
        landMarks: {}
    },
    vehicleTreatmentList: []
};

export const everShineReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_WORKSHOPS_LIST:
            return {
                ...state,
                shopLists: action.payload,
            };
        case ActionTypes.SELECT_LAND_MARK_SIDEBAR:
            return {
                ...state,
                landMarkSidebar: {
                    open: action.payload.open,
                    landMarks: action.payload.landMarks
                },
            };
        case ActionTypes.GET_VEHICLE_TREATMENT_HISTORY:
            return {
                ...state,
                vehicleTreatmentList: action.payload,
            };
        default:
        return state;
    }
}