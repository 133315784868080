/* eslint-disable */
import React, { useEffect, useState } from "react";
import Map from "../common/Map";
import GetWorkShopsAPI from "../../API/GetWorkShopsAPI";
import { useDispatch } from "react-redux";
import { getWorkShopsLists, getLandMarkSidebar } from "../../redux/actions/everShineActions";
import { Link } from "react-router-dom";
import { HOME_URL } from "../Shared/constant";
import Logo from "../../assets/img/logo.png";
import SearchIcon from "../../assets/img/searchIcon-white.png";
import SearchClearIcon from "../../assets/img/close_search.png";
import BackIcon from "../../assets/img/back.png";
import Autocomplete from "react-google-autocomplete";
import GetAddressAPI from "../../API/GetAddressAPI";
import CommonLoader from "../common/CommonLoader";

const NearWorkshop = () => {
    const Dispatch = useDispatch();
    const [ LoaderStart, setLoaderStart ] = useState(false);
    const [ SearchCommon, setSearchCommon ] = useState("");

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);

    const [latitudeCurrent, setLatitudeCurrent] = useState(null);
    const [longitudeCurrent, setLongitudeCurrent] = useState(null);

    const [ center, setCenter ] = useState({ 
        lat: 59.744076, 
        lng: 10.204456,
    });
    const [ RefreshMap, setRefreshMap ] = useState(false);
    
    // clear search list
    const ClearSearch = () => {
        setSearchCommon("");
    };

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);

                setLatitudeCurrent(position.coords.latitude);
                setLongitudeCurrent(position.coords.longitude); 
                setRefreshMap(true);
                setCenter({ 
                    ...center,
                    lat: position.coords.latitude, 
                    lng: position.coords.longitude
                });
            }, (err) => {
                setError(err.message);
                }
            );
        } else {
            setError('Geolocation is not supported by your browser');
        };
    }, []);

    const GetFullAddressLatLng = (place) => {
        if(place?.geometry !== undefined) {
            Dispatch(getLandMarkSidebar({open: false, landMarks: {}}))
            // setLatitude(place?.geometry?.location?.lat());
            // setLongitude(place?.geometry?.location?.lng());
            setRefreshMap(true)
            setCenter({ 
                ...center,
                lat: place?.geometry?.location?.lat(), 
                lng: place?.geometry?.location?.lng()
            });
        };
    };

    useEffect(() => {
        setTimeout(() => {
            setRefreshMap(false)
        }, [ 2000 ]);
    }, [ RefreshMap ])

    useEffect(() => {
        if(latitude !== null && longitude !== null ) {
            GetCurrentAddress();
            fetchData();
        } else {
            fetchData();
        };
    }, [ latitude, longitude ]);

    // Get data from api
    const fetchData = async () => {
        const responce = await GetWorkShopsAPI(latitude, longitude, setLoaderStart);
        if(responce?.status === 200) {
            Dispatch(getWorkShopsLists(responce?.data));
        } else {
            Dispatch(getWorkShopsLists([]));
        };
    };

    // get current address api
    const GetCurrentAddress = async () => {
        const responce = await GetAddressAPI(latitude, longitude);
        if(responce?.status === 200) {
            const addressComponents = responce?.results[0]?.address_components;
            let city = '';
            let state = '';
            let country = '';

            // Loop through address components to find city, state, and country
            if(addressComponents !== undefined) {
                addressComponents.forEach((component) => {
                    if (component.types.includes('locality')) {
                        city = component.long_name;
                    } else if (component.types.includes('administrative_area_level_1')) {
                        state = component.long_name;
                    } else if (component.types.includes('country')) {
                        country = component.long_name;
                    }
                });
    
                const SelectedLocations = document.getElementById("selectedLocations");
                SelectedLocations.value = `${city}, ${state}, ${country}` 
            }
        };
    };

    return(<React.Fragment>
        {/* new header fro map */}
        <header className="searchBoxWrapperMain">
            <Link to={HOME_URL} className="btn backbtn" >
                <img src={BackIcon} className="logoicon" alt="logo" />
            </Link>
            <div className="searchBoxWrapper">
                <img src={Logo} className="logoicon respohidelogo" alt="logo" />
                <div className="searchBox">
                    <Autocomplete
                        id="selectedLocations"
                        apiKey={process.env.REACT_APP_MAP_GOOGLE_API_KEY}
                        onPlaceSelected={(place) => GetFullAddressLatLng(place)}
                        options={{
                            types: ['geocode']
                        }}
                    />
                    {SearchCommon !== "" && (<button className="clearbtn" onClick={() => ClearSearch()}>
                        <img src={SearchClearIcon} alt="Clear Icon" />
                    </button>)}
                    <button className="btn btnsearchbX">
                        <img src={SearchIcon} alt="Search Icon" />
                    </button>
                </div>
                
            </div>
        </header>
        <section className="serachMapLocation">
            <div className="freeGoggleMap">
                {RefreshMap ? (setTimeout(() => {
                    <Map 
                        latitudeCurrent={latitudeCurrent} 
                        longitudeCurrent={longitudeCurrent}
                        center={center} 
                        zoom={10} 
                    />
                }, 100)) : (<Map 
                    latitudeCurrent={latitudeCurrent} 
                    longitudeCurrent={longitudeCurrent}
                    center={center} 
                    zoom={10} 
                />)}        
            </div>
        </section>
        {RefreshMap && <CommonLoader type={"mapsearch"} />}
    </React.Fragment>
    )
}

export default NearWorkshop;