import React from 'react';
import GoogleMapReact from 'google-map-react';
import LandMarkIcon from '../../assets/img/landmark_icon.png';
import LandMarkDetails from './LandMarkDetails';
import { getLandMarkSidebar } from '../../redux/actions/everShineActions';
import { useSelector, useDispatch } from 'react-redux';

const Map = ( props ) => {
    const { center, zoom, latitudeCurrent, longitudeCurrent  } = props;
    const Dispatch = useDispatch();
    const { shopLists, landMarkSidebar } = useSelector((state) => state?.everShineReducers);

    const LandmarkMarker = ({ text, data_ }) => (
        <div
          style={{
            color: '#f14436',
            display: 'inline-flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            transform: 'translate(-50%, -50%)',
            fontWeight: "500",
            textTransform: "capitalize",
            columnGap: "5px",
            fontSize: "14px"
          }}
          onClick={() => Dispatch(getLandMarkSidebar({open: true, landMarks: data_}))}
        >
            <img src={LandMarkIcon} style={{ width: "40px" }} alt='loader' />
            {text}
        </div>
    );

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <LandMarkDetails 
              details={landMarkSidebar} 
              latitudeCurrent={latitudeCurrent} 
              longitudeCurrent={longitudeCurrent}
            />
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_GOOGLE_API_KEY }} // Replace with your API key
                defaultCenter={center}
                defaultZoom={zoom}
            >
                {shopLists?.map((elm, index) => {
                    return(<LandmarkMarker 
                        key={index}
                        lat={elm?.latitude !== null && elm?.latitude}
                        lng={elm?.longitude !== null && elm?.longitude}
                        text={elm?.workshop_name}
                        data_={elm}
                    />)
                })}
            </GoogleMapReact>
        </div>
    );
};

// Map.defaultProps = {
//   center: { lat: 18.5499648, lng: 73.9115008 }, // Default center if none provided
//   zoom: 11, // Default zoom level if none provided
// };

export default Map;
