import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./assets/css/media.css";
import AuthLayout from './components/Shared/AuthLayout';
import Home from './components/Home';
import { HOME_URL, NEAR_BY_WORKSHOP_URL, CHECK_YOUR_TREATMENT_URL } from './components/Shared/constant';
import NearWorkshop from './components/NearWorkshop';
import CheckTreatment from './components/CheckTreatment';
import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App() {
  return (
    <div className="wrapper">
      <BrowserRouter>
          <Routes>
              <Route path="*" element={<Navigate replace to={HOME_URL}/>} />
              <Route path={HOME_URL} element={<AuthLayout><Home /></AuthLayout>} />
              <Route path={NEAR_BY_WORKSHOP_URL} element={<NearWorkshop />} />
              <Route path={CHECK_YOUR_TREATMENT_URL} element={<CheckTreatment />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;